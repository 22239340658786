import React, { PropsWithChildren } from 'react';
import { Container } from 'reactstrap';
import './Shell.css';

const Shell: React.FC<PropsWithChildren> = ({ children }) => {
    return (<div className="fullscreen-height">
        <div className="navbar navbar-expand-lg navbar-dark bg-dark">
            <Container fluid>
                <img className='navbar-brand' src='/images/RefMedLogoReversed.png' alt='RefMed Logo' style={{ height: '60px' }}></img>
                <div style={{ flex: 1 }}></div>
                <span className="navbar-text">
                    {require('../../package.json').version}
                </span>
            </Container>
        </div>
        <Container fluid className="main-content">
            {children}
        </Container>
    </div>);
};

export default Shell;