import APIDataSource from './datasource/APIDataSource';
import Demo from './pages/Demo';
import Shell from './pages/Shell';

const dataSource = new APIDataSource();

function App() {
  return (
    <Shell>
      <Demo dataSource={dataSource} />
    </Shell>
  );
}

export default App;
