import HospitalCode from "./types/HospitalCode";
import HospitalCodeRatesResponse from "./types/HospitalCodeRatesResponse";
import HospitalCodeSearchResponse from "./types/HospitalCodeSearchResponse";
import ResultWrapper from "./types/ResultWrapper";

type PromiseResult<T> = Promise<ResultWrapper<T>>;

class APIDataSource {
    private cache = new Map<string, ResultWrapper<any>>();

    private async cachePost(resource: string, body: any, signal: AbortSignal, cacheKey: string) {
        if (cacheKey != null) {
            cacheKey = `${resource}//${cacheKey}`;
            const value = this.cache.get(cacheKey);
            if (value)
                return value;
        }

        const result = await this.post_impl(resource, body, false, signal)

        if (cacheKey != null) {
            this.cache.set(cacheKey, result);
        }
        return result;
    }

    private async post_impl(resource: string, body: any, fallible: boolean, signal: AbortSignal) {
        const endpoint = '/PassThrough/' + resource + '/design';
        //const token = await authService.getAccessToken(signal);
        const response = await fetch(endpoint, {
            method: 'POST',
            body: JSON.stringify(body),
            signal,
            headers: new Headers({
                //'Authorization': `Bearer ${token}`
                'Content-Type': 'application/json'
            })
        });
        if (!response.ok)
            throw new Error(`API Request ${endpoint} failed with ${response.statusText ? (`'${response.statusText}'`) : ('HTTP status ' + response.status)}.`);
        const result: ResultWrapper<any> = await response.json();
        if (!fallible && !result.status)
            throw new Error(`API Request ${endpoint} failed: ${result.error_message ?? 'no error_message'}.`);
        return result;
    }

    searchHospitalCodes(search: string, signal: AbortSignal): PromiseResult<HospitalCodeSearchResponse> {
        return this.cachePost('hospitalcodesearch', { SEARCH_STRING: search }, signal, search);
    }

    getHospitalCodeRates(code: HospitalCode, signal: AbortSignal): PromiseResult<HospitalCodeRatesResponse> {
        return this.cachePost('hospitalcoderates', code, signal, `${code.GENERAL_CODE_TYPE}//${code.GENERAL_CODE}`);
    }
}

export default APIDataSource;