import { useEffect, useMemo, useState } from "react";
import APIDataSource from "../datasource/APIDataSource";
import handleAbortError from "../datasource/handleAbortError";
import Table, { MyColumn } from "../components/Table";
import { CellProps } from "react-table";
import AsyncSelect from "react-select/async"
import { HospitalCodeRate } from "../datasource/types/HospitalCodeRatesResponse";
import { HospitalCodeSearchEntry } from "../datasource/types/HospitalCodeSearchResponse";
import { debounce } from "lodash";

interface DemoProps {
    dataSource: APIDataSource;
}

const formatCurrency = (item: CellProps<HospitalCodeRate, number>, specialNA?: boolean) => {
    const c = item.value;
    if (c === undefined || c === null || isNaN(c))
        return 'N/A';
    if (c === 0) {
        if (specialNA && item.row.original.GENERAL_HIGH_NEG === 0)
            return 'N/A';
        else if (!specialNA)
            return 'N/A';
    }
    return '$' + c.toFixed(2);
}

const RightAlign = ({ value }: { value: any }) => <span style={{ textAlign: 'right', display: 'block', width: '100%', paddingRight: '10px' }}>{value}</span>

const Currency = ({ item, specialNA }: { item: CellProps<HospitalCodeRate, number>, specialNA?: boolean }) => <RightAlign value={formatCurrency(item, specialNA)} />

const columns: MyColumn<HospitalCodeRate>[] = [
    { accessor: 'HOSPITAL_NAME', Header: 'Hospital Name', Cell: item => <span style={{ whiteSpace: 'nowrap' }}>{item.value}</span> },
    { accessor: 'GENERAL_MOD_1', Header: '', disableFilters: true, disableSortBy: true },
    { accessor: 'GENERAL_GROSS', Header: <span title="Amount charged when no discounts apply">Gross Price</span>, textAlign: 'right', Cell: (item) => <Currency item={item} />, disableFilters: true },
    { accessor: 'GENERAL_CASH', Header: <span title="Charge that applies when patient pays cash">Cash Price</span>, textAlign: 'right', Cell: (item) => <Currency item={item} />, disableFilters: true },
    { accessor: 'GENERAL_LOW_NEG', Header: <span title="Lowest charge hospital has negotiated with any insurance company">Lowest Negotiated</span>, textAlign: 'right', Cell: (item) => <Currency item={item} specialNA />, disableFilters: true },
    { accessor: 'GENERAL_HIGH_NEG', Header: <span title="Highest charge hospital has negotiated with any insurance company">Highest Negotiated</span>, textAlign: 'right', Cell: (item) => <Currency item={item} />, disableFilters: true }
];

const Demo: React.FC<DemoProps> = ({ dataSource }) => {
    const [selectedRevenueCode, setSelectedRevenueCode] = useState<HospitalCodeSearchEntry | null>(null);
    const [chargeData, setChargeData] = useState<HospitalCodeRate[]>([]);

    useEffect(() => {
        if (!selectedRevenueCode) {
            setChargeData([]);
            return;
        }
        const abortController = new AbortController();
        dataSource.getHospitalCodeRates(selectedRevenueCode, abortController.signal)
            .then(response => setChargeData(response.result.HOSPITALCODERATES))
            .catch(handleAbortError);
        return () => abortController.abort();
    }, [dataSource, selectedRevenueCode]);

    const chargeSearch = useMemo(() =>
        debounce((search: string, callback: (codes: HospitalCodeSearchEntry[]) => void) => {
            const unusedSignal = new AbortController().signal;
            dataSource.searchHospitalCodes(search, unusedSignal)
                .then(result => callback(result.result.HOSPITALCODES));
        }, 250), [dataSource]);

    useEffect(() => chargeSearch.cancel(), [chargeSearch]);

    return <>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '10px' }}>
            <label style={{ whiteSpace: "nowrap", marginRight: '6px' }} htmlFor='Charge'>Select Charge:</label>
            <AsyncSelect<HospitalCodeSearchEntry>
                id="Charge"
                getOptionLabel={r => r.DESCRIPTION}
                getOptionValue={r => `${r.GENERAL_CODE_TYPE}/${r.GENERAL_CODE}`}
                loadOptions={chargeSearch}
                onChange={code => setSelectedRevenueCode(code)}
                value={selectedRevenueCode}
                styles={{ container: baseStyles => ({ ...baseStyles, width: '100%' }) }}
                noOptionsMessage={({ inputValue }) => inputValue ? 'No Codes' : 'Enter a search value'}
                placeholder="Start typing to search..."
            />
        </div>
        <hr></hr>
        <Table
            data={chargeData}
            columns={columns}
            initialState={{ sortBy: [{ id: 'GENERAL_GROSS', desc: true }] }}
        />
    </>
};

export default Demo;