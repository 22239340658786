import { Column, ColumnInstance, FilterProps, TableState, useFilters, usePagination, useSortBy, useTable } from 'react-table';
import './Table.css';
import TablePagination from './TablePagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faFilterCircleXmark, faSort, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { Popover, PopoverHeader, PopoverBody, CloseButton, Input, Button } from 'reactstrap'
import { MouseEvent, useEffect, useState } from 'react';
import { Property } from 'csstype';

library.add(faFilter, faFilterCircleXmark, faSort, faSortDown, faSortUp);

const TextFilter = <T extends object>(props: FilterProps<T>) => {
  const { column: { filterValue, setFilter } } = props;
  const id = props.column.id as string;
  const headerName = props.column.Header as string;
  const [open, setOpen] = useState(false);
  const toggle = (e: MouseEvent<HTMLSpanElement>) => {
    setOpen(!open);
    e.stopPropagation();
  };

  return (<>
    <Popover target={id} isOpen={open} placement="bottom" >
      <PopoverHeader style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <span><CloseButton onClick={(e) => toggle(e)} /></span>
        <span style={{ paddingLeft: "4px" }}>{headerName}</span>
        {filterValue && <span style={{ paddingLeft: "4px" }}><Button onClick={() => setFilter(undefined)} size='sm'>Clear</Button></span>}
      </PopoverHeader>
      <PopoverBody>
        <Input
          value={filterValue || ''}
          onChange={e => {
            setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
          }}
          placeholder="Filter"
        />
      </PopoverBody>
    </Popover>
    <span id={id} onClick={toggle} style={{ textAlign: 'right', marginLeft: '4px' }}>
      {filterValue ? <FontAwesomeIcon icon={faFilterCircleXmark} /> : <FontAwesomeIcon icon={faFilter} size="sm" />}
    </span>
  </>
  )
}

export type MyColumn<T extends Object> = (Column<T> & { textAlign?: Property.TextAlign });

interface TableProps<T extends object> {
  columns: MyColumn<T>[];
  data: T[];
  rowDoubleClickHandler?: (x: T) => void;
  disableFilters?: boolean;
  disableSortBy?: boolean;
  overrideFilters?: Partial<{ [I in keyof T]: string }>;
  initialState?: Partial<TableState<T>>
}

const Table = <T extends object>({ columns, data, rowDoubleClickHandler, disableFilters, disableSortBy, overrideFilters, initialState }: TableProps<T>) => {
  const instance = useTable({
    columns,
    data,
    defaultColumn: {
      Filter: TextFilter
    },
    disableFilters: disableFilters,
    disableSortBy: disableSortBy,
    initialState: { pageSize: 10, ...initialState }
  }, useFilters, useSortBy, usePagination);

  useEffect(() => {
    if (overrideFilters) {
      for (const [key, value] of Object.entries(overrideFilters)) {
        instance.setFilter(key, value);
      }
    }
  }, [overrideFilters, instance]);

  const extraHeaderProps = (c: ColumnInstance<T>): { style?: React.CSSProperties } => {
    const column = columns.find(col => c.Header === col.Header);
    if (!column?.textAlign)
      return {};
    return { style: { textAlign: column.textAlign } };
  }

  return (<div className='my-table'><div className='wrapper'>
    <table {...instance.getTableProps()}>
      <thead>
        <tr>
          {instance.headers.map(c => <th {...c.getHeaderProps(extraHeaderProps(c))}>
            <span {...c.getSortByToggleProps()}>
              {c.render('Header')}
            </span>
            <span>
              {c.canSort && (c.isSorted
                ? c.isSortedDesc
                  ? <FontAwesomeIcon style={{ marginLeft: '4px' }} icon={faSortDown} />
                  : <FontAwesomeIcon style={{ marginLeft: '4px' }} icon={faSortUp} />
                : <FontAwesomeIcon style={{ marginLeft: '4px' }} icon={faSort} />)}
            </span>
            {c.canFilter && c.render('Filter')}
          </th>)}
        </tr>

      </thead>
      <tbody {...instance.getTableBodyProps()}>
        {instance.page.map(r => {
          instance.prepareRow(r);
          return <tr {...r.getRowProps()} onDoubleClick={() => rowDoubleClickHandler && rowDoubleClickHandler(r.original)}>
            {r.cells.map(c => <td {...c.getCellProps()}>{c.render('Cell')}</td>)}
          </tr>
        })}
      </tbody>
    </table>
    <TablePagination instance={instance} total={data.length} />
  </div></div>);
};

export default Table;